import NextLink, { LinkProps as NextLinkProps } from "next/link";

interface LinkProps extends NextLinkProps {
  className?: string;
  id?: string;
  target?: string;
}

export function Link({ id, href, children, ...props }: React.PropsWithChildren<LinkProps>) {
  return (
    <NextLink id={id} href={href} {...props}>
      {children}
    </NextLink>
  );
}
