import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeBannerComponentSkeleton } from "./TypeBannerComponent";
import type { TypeCardRowComponentSkeleton } from "./TypeCardRowComponent";
import type { TypeFaqComponentSkeleton } from "./TypeFaqComponent";
import type { TypeHeroCarouselComponentSkeleton } from "./TypeHeroCarouselComponent";
import type { TypeRichTextComponentSkeleton } from "./TypeRichTextComponent";
import type { TypeSeoSkeleton } from "./TypeSeo";
import type { TypeSubscriptionComponentSkeleton } from "./TypeSubscriptionComponent";

/**
 * Fields type definition for content type 'TypeBlogPage'
 * @name TypeBlogPageFields
 * @type {TypeBlogPageFields}
 * @memberof TypeBlogPage
 */
export interface TypeBlogPageFields {
    /**
     * Field type definition for field 'title' (Title)
     * @name Title
     * @localized true
     */
    title: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'menuTitle' (Menu Title)
     * @name Menu Title
     * @localized false
     */
    menuTitle?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'slug' (Slug)
     * @name Slug
     * @localized true
     */
    slug: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'seoMetadata' (SEO Metadata)
     * @name SEO Metadata
     * @localized false
     */
    seoMetadata?: EntryFieldTypes.EntryLink<TypeSeoSkeleton>;
    /**
     * Field type definition for field 'header' (Header)
     * @name Header
     * @localized false
     */
    header?: EntryFieldTypes.EntryLink<TypeBannerComponentSkeleton>;
    /**
     * Field type definition for field 'featuredPosts' (Featured Posts)
     * @name Featured Posts
     * @localized false
     */
    featuredPosts: EntryFieldTypes.EntryLink<TypeCardRowComponentSkeleton>;
    /**
     * Field type definition for field 'content' (Content)
     * @name Content
     * @localized false
     */
    content?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeBannerComponentSkeleton | TypeCardRowComponentSkeleton | TypeFaqComponentSkeleton | TypeHeroCarouselComponentSkeleton | TypeRichTextComponentSkeleton | TypeSubscriptionComponentSkeleton>>;
}

/**
 * Entry skeleton type definition for content type 'blogPage' (📄 Blog Page)
 * @name TypeBlogPageSkeleton
 * @type {TypeBlogPageSkeleton}
 * @author 5cwkd2r4Mn9VcVSLkPm6Tm
 * @since 2023-11-03T14:28:23.595Z
 * @version 31
 */
export type TypeBlogPageSkeleton = EntrySkeletonType<TypeBlogPageFields, "blogPage">;
/**
 * Entry type definition for content type 'blogPage' (📄 Blog Page)
 * @name TypeBlogPage
 * @type {TypeBlogPage}
 * @author 5cwkd2r4Mn9VcVSLkPm6Tm
 * @since 2023-11-03T14:28:23.595Z
 * @version 31
 */
export type TypeBlogPage<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeBlogPageSkeleton, Modifiers, Locales>;

export function isTypeBlogPage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeBlogPage<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'blogPage'
}
