export const ROUTES = {
  HOME: "/",
  PRODUCT: "/products",
  BASKET: "/basket",
  CHECKOUT: "/checkout",
  CONTACT: "/contact-us",
  TERMS: "/terms-and-conditions",
  PRIVACY_POLICY: "/privacy-policy",
  COOKIE_POLICY: "/cookie-policy",
  FAQ: "/faq",
  FORGET_PASSWORD: "/forget-password",
  ACCOUNT: "/my-account",
  ORDERS: "/my-account/orders",
  QUOTES: "/my-account/quotes",
  ACCOUNT_DETAILS: "/my-account/account-details",
  CREDIT_ACCOUNT: "/my-account/credit-account",
  ADDRESS_MANAGEMENT: "/my-account/address-management",
  CARD_MANAGEMENT: "/my-account/card-management",
  CHANGE_PASSWORD: "/my-account/change-password",
  PRINT_TOOLS: "/my-account/print-tools",
  LOGIN: "/signin",
  SIGN_UP: "/signup",
  SEARCH: "/search",
  COLLECTIONS: "/collections",
  CATEGORY: "/category",
  ORDER: "/order",
  REGISTER: "/register",
  BESPOKE: "/bespoke",
  NOTIFICATIONS: "/my-account/notifications",
  BLOG: "/blog",
  NOT_FOUND: "/not-found",
};
