import { UrlObject } from "url";
import Link, { LinkProps } from "next/link";
import { CSSProperties } from "react";

interface OptionalLinkProps extends Omit<LinkProps, "href"> {
  href?: string | UrlObject | null;
  className?: string;
  style?: CSSProperties;
}

export function OptionalLink({
  href,
  children,
  className,
  style,
  ...props
}: React.PropsWithChildren<OptionalLinkProps>) {
  if (!href) {
    return (
      <span className={className} style={style}>
        {children}
      </span>
    );
  }
  return (
    <Link href={href} className={className} style={style} {...props}>
      {children}
    </Link>
  );
}
