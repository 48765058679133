import { config } from "@/config";
import { ROUTES } from "@/features/store/settings/routes";
import {
  isTypeBespokePage,
  isTypeBlogArticlePage,
  isTypeBlogPage,
  isTypeCategoryPage,
  isTypeContactUsPage,
  isTypeContentPage,
  isTypeHomePage,
  isTypeProductPage,
} from "./ts-generated";
import { CtfEntry } from "./types";

export type EntryWithSlug = CtfEntry & { fields: { slug: string } };

export function getPath(page?: EntryWithSlug): string {
  if (!page) return ROUTES.NOT_FOUND;

  const slug = page.fields.slug;
  if (!isTypeHomePage(page) && !slug) return ROUTES.NOT_FOUND;

  switch (true) {
    case isTypeCategoryPage(page):
      return `${ROUTES.CATEGORY}/${slug}`;
    case isTypeProductPage(page):
      return `${ROUTES.PRODUCT}/${slug}`;
    case isTypeBlogPage(page):
      return `${ROUTES.BLOG}`;
    case isTypeBlogArticlePage(page):
      return `${ROUTES.BLOG}/${slug}`;
    case isTypeBespokePage(page):
      return `${ROUTES.BESPOKE}`;
    case isTypeContentPage(page):
      return `/${slug}`;
    case isTypeContactUsPage(page):
      return `/${slug}`;
    case isTypeHomePage(page):
      return "/";
    default:
      return ROUTES.NOT_FOUND;
  }
}

/**
 * This function attempts to get the URL path for a given page type and slug. It does
 * this by creating the bare minimum object required to pass to the type guard functions
 * generated by the Contentful/TypeScript CLI. They look something like this:
 *    `return entry.sys.contentType.sys.id === 'someContentType'`
 * So as long as we've specified the correct `pageType` and `slug`, we should be able to
 * get the correct path.
 * @param pageType The type of page, for example 'productPage'
 * @param slug The slug of the page, for example 'cool-flyers'
 * @returns The path, for example '/products/cool-flyers'
 */
export function tryGetPath(pageType: string, slug: string): string {
  if (!pageType) return ROUTES.NOT_FOUND;
  const fakeEntry = {
    sys: { contentType: { sys: { id: pageType } } },
    fields: { slug },
  } as EntryWithSlug;
  return getPath(fakeEntry);
}

export function getUrl(page?: EntryWithSlug): string {
  return `${config.NEXT_PUBLIC_BASE_URL}${getPath(page)}`;
}
