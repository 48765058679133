import { ContentfulLivePreview } from "@contentful/live-preview";
import { ChainModifiers, Entry, EntrySkeletonType, LocaleCode } from "contentful";

/**
 * Retrieves the live preview props to apply to an element for a given entry and field.
 * This is a simple wrapper function that makes the live preview usage more concise.
 *
 * @param entry - The Contentful entry to generate the live preview props for.
 * @param field - The field of the entry to generate the live preview props for.
 * @returns The live preview props for the given entry and field.
 */
export function getLivePreviewProps<
  T extends Entry<EntrySkeletonType, Modifiers, Locales>,
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
>(entry: T, field: keyof T["fields"]) {
  return ContentfulLivePreview.getProps({ entryId: entry.sys.id, fieldId: field as string });
}
