import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeBespokeQuoteFormComponentSkeleton } from "./TypeBespokeQuoteFormComponent";
import type { TypeSeoSkeleton } from "./TypeSeo";

/**
 * Fields type definition for content type 'TypeBespokePage'
 * @name TypeBespokePageFields
 * @type {TypeBespokePageFields}
 * @memberof TypeBespokePage
 */
export interface TypeBespokePageFields {
    /**
     * Field type definition for field 'heroImage' (Hero Image)
     * @name Hero Image
     * @localized false
     */
    heroImage?: EntryFieldTypes.AssetLink;
    /**
     * Field type definition for field 'content' (Content)
     * @name Content
     * @localized false
     * @summary Add content to be displayed on bespoke page
     */
    content: EntryFieldTypes.RichText;
    /**
     * Field type definition for field 'bespokeQuoteForm' (Bespoke Quote Form)
     * @name Bespoke Quote Form
     * @localized false
     */
    bespokeQuoteForm?: EntryFieldTypes.EntryLink<TypeBespokeQuoteFormComponentSkeleton>;
    /**
     * Field type definition for field 'slug' (slug)
     * @name slug
     * @localized true
     */
    slug: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'title' (Title)
     * @name Title
     * @localized false
     */
    title?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'seoMetadata' (Seo Metadata)
     * @name Seo Metadata
     * @localized false
     */
    seoMetadata?: EntryFieldTypes.EntryLink<TypeSeoSkeleton>;
    /**
     * Field type definition for field 'iFrameWidth' (IFrame Width)
     * @name IFrame Width
     * @localized false
     */
    iFrameWidth?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'iFrameHeight' (IFrame Height)
     * @name IFrame Height
     * @localized false
     */
    iFrameHeight?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'iFrameUrl' (IFrame URL)
     * @name IFrame URL
     * @localized false
     */
    iFrameUrl?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'pageContent' (Page Content)
     * @name Page Content
     * @localized false
     */
    pageContent?: EntryFieldTypes.RichText;
}

/**
 * Entry skeleton type definition for content type 'bespokePage' (📄 Bespoke Page)
 * @name TypeBespokePageSkeleton
 * @type {TypeBespokePageSkeleton}
 * @author 75mbCkxwKQWXXbDUSJMuii
 * @since 2024-03-14T01:21:44.562Z
 * @version 3
 */
export type TypeBespokePageSkeleton = EntrySkeletonType<TypeBespokePageFields, "bespokePage">;
/**
 * Entry type definition for content type 'bespokePage' (📄 Bespoke Page)
 * @name TypeBespokePage
 * @type {TypeBespokePage}
 * @author 75mbCkxwKQWXXbDUSJMuii
 * @since 2024-03-14T01:21:44.562Z
 * @version 3
 */
export type TypeBespokePage<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeBespokePageSkeleton, Modifiers, Locales>;

export function isTypeBespokePage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeBespokePage<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'bespokePage'
}
