import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeCardRowComponentSkeleton } from "./TypeCardRowComponent";
import type { TypeSeoSkeleton } from "./TypeSeo";
import type { TypeSubscriptionComponentSkeleton } from "./TypeSubscriptionComponent";

/**
 * Fields type definition for content type 'TypeContactUsPage'
 * @name TypeContactUsPageFields
 * @type {TypeContactUsPageFields}
 * @memberof TypeContactUsPage
 */
export interface TypeContactUsPageFields {
    /**
     * Field type definition for field 'title' (Title)
     * @name Title
     * @localized true
     */
    title: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'menuTitle' (Menu Title)
     * @name Menu Title
     * @localized false
     */
    menuTitle?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'slug' (Slug)
     * @name Slug
     * @localized false
     */
    slug: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'seoMetadata' (SEO Metadata)
     * @name SEO Metadata
     * @localized false
     */
    seoMetadata?: EntryFieldTypes.EntryLink<TypeSeoSkeleton>;
    /**
     * Field type definition for field 'links' (Links)
     * @name Links
     * @localized false
     */
    links?: EntryFieldTypes.EntryLink<TypeCardRowComponentSkeleton>;
    /**
     * Field type definition for field 'content' (Content)
     * @name Content
     * @localized false
     */
    content?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeSubscriptionComponentSkeleton>>;
}

/**
 * Entry skeleton type definition for content type 'contactUsPage' (📄 Contact Us Page)
 * @name TypeContactUsPageSkeleton
 * @type {TypeContactUsPageSkeleton}
 * @author 0kZNfwixsbsTjcGzU3RIqE
 * @since 2024-07-24T13:00:10.886Z
 * @version 1
 */
export type TypeContactUsPageSkeleton = EntrySkeletonType<TypeContactUsPageFields, "contactUsPage">;
/**
 * Entry type definition for content type 'contactUsPage' (📄 Contact Us Page)
 * @name TypeContactUsPage
 * @type {TypeContactUsPage}
 * @author 0kZNfwixsbsTjcGzU3RIqE
 * @since 2024-07-24T13:00:10.886Z
 * @version 1
 */
export type TypeContactUsPage<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeContactUsPageSkeleton, Modifiers, Locales>;

export function isTypeContactUsPage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeContactUsPage<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'contactUsPage'
}
